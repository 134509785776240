/* eslint-disable import/no-unused-modules */
import React from 'react';
import styled from 'styled-components';
import { Stack } from '@mui/material';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { range } from 'lodash';
import ContactOffices from '../components/ContactOffices';
import Helmet from '../components/Helmet';
import { H2, P } from '../components/Text';
import TransHTML from '../components/TransHTML';
import JobPosting from '../modules/about-us/JobPosting';
import { PageLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';
import {
  FONT_BOLD,
  screenLessThan,
  SPACE_LG,
  SPACE_MD,
} from '../shared/styles';
import BizCar from '../../static/images/biz-car.jpg';
import ticky from '../../static/svgs/features/ticky.svg';
import RedCircle from '../../static/svgs/home/redCircle.svg';

export const Head = withI18n(() => {
  return <Helmet pageName="aboutUs" />;
});

const AboutUs = () => {
  const { t } = useTranslation();
  const aboutUsLines = 7;
  return (
    <PageLayout>
      <BackgroundImageRed src={RedCircle} />
      <Stack direction={{ xs: 'column', lg: 'row' }} gap={{ xs: 4, md: 14 }}>
        <Stack>
          <AboutUsTitle align="start">{t('about-us.title')}</AboutUsTitle>
          <AboutUsDescription align="start">
            {t('about-us.subheading')}
          </AboutUsDescription>
        </Stack>
        <BizCarImage src={BizCar} />
      </Stack>
      <AboutUsSubWrapper>
        <AboutUsSubtitle align="center">
          {t('about-us.sub-title')}
        </AboutUsSubtitle>
        <AboutUsBody>
          <TransHTML contentKey="about-us.introduction" />
        </AboutUsBody>
        <Stack my={4}>
          {range(aboutUsLines).map((numbers) => (
            <Stack gap={3} mb={3} direction="row" key={numbers}>
              <img src={ticky} alt="about-us-tick" />
              <P align="left" className="mb-0">
                {t(`about-us.questions.${numbers + 1}`)}
              </P>
            </Stack>
          ))}
        </Stack>

        <AboutUsBody>
          <TransHTML contentKey="about-us.subheading-2" />
        </AboutUsBody>
        <ContactOffices />
      </AboutUsSubWrapper>
      <JobPosting />
    </PageLayout>
  );
};

export default AboutUs;

const AboutUsSubWrapper = styled.div`
  background-color: white;
  margin-left: -${SPACE_LG};
  margin-right: -${SPACE_LG};
  padding-left: ${SPACE_LG};
  padding-right: ${SPACE_LG};
  ${screenLessThan('1000px')} {
    margin-left: -${SPACE_MD};
    margin-right: -${SPACE_MD};
    padding-left: ${SPACE_MD};
    padding-right: ${SPACE_MD};
  }
`;

const BackgroundImageRed = styled.img`
  filter: blur(90px);
  left: 60%;
  margin: auto;
  pointer-events: none;
  position: absolute;
  top: 5%;
  width: 17%;
  z-index: -10;
  ${screenLessThan('700px')} {
    filter: blur(50px);
    left: 35%;
    top: 6%;
    width: 30%;
  }
`;

const AboutUsTitle = styled(H2)`
  font-size: 72px;
  line-height: 1.5;
  ${FONT_BOLD};
  margin-bottom: 1rem;
  ${screenLessThan('1000px')} {
    font-size: 2.7rem;
  }
  ${screenLessThan('600px')} {
    font-size: 38px;
  }
`;

const AboutUsDescription = styled(P)`
  margin-bottom: 0;
`;

const BizCarImage = styled.img`
  margin: 0rem auto 3rem;
  width: 600px;
  ${screenLessThan('1000px')} {
    width: 80%;
  }
`;

const AboutUsSubtitle = styled(H2)`
  font-weight: ${FONT_BOLD};
  margin-top: 4rem;
  ${screenLessThan('1000px')} {
    text-align: left;
  }
`;

const AboutUsBody = styled.div`
  line-height: 2;
`;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
