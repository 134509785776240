import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Stack } from '@mui/material';
import { Link } from 'gatsby';
import { Button } from '../../components/Button';
import { H2, P } from '../../components/Text';

import { BIZ_LINKEDIN_JOB_LINK } from '../../config';
import { FONT_BOLD, screenLessThan } from '../../shared/styles';
import JobPostingImg from '../../../static/images/job-posting.jpg';
import blueCircle from '../../../static/svgs/home/blueCircle.svg';

const JobPosting = () => {
  const { t } = useTranslation();
  return (
    <Stack
      mt={15}
      mb={3}
      direction={{ xs: 'column', md: 'row' }}
      gap={{ xs: 5, md: 15 }}
    >
      <BlueCircleBackGround src={blueCircle} alt="blue-circle" />
      <SectionImage src={JobPostingImg} alt="biz-web" />
      <Stack alignItems={{ xs: 'center', lg: 'flex-start' }}>
        <JobPostingTitle>{t('about-us.job-header')}</JobPostingTitle>
        <JobPostingSubtitle>{t('about-us.job-subheading')}</JobPostingSubtitle>
        <JobPostingButton as={Link} target="_blank" to={BIZ_LINKEDIN_JOB_LINK}>
          {t('about-us.button-text')}
        </JobPostingButton>
      </Stack>
    </Stack>
  );
};

const BlueCircleBackGround = styled.img`
  filter: blur(50px);
  left: 25%;
  pointer-events: none;
  position: absolute;
  top: 49%;
  transform: translate(-50%, -50%);
  width: 20vw;
  z-index: -1;
  ${screenLessThan('700px')} {
    filter: blur(40px);
    left: 70%;
    top: 50%;
    width: 55%;
  }
`;

const SectionImage = styled.img`
  margin: auto;
  width: 400px;
  ${screenLessThan('1000px')} {
    max-width: 80%;
  }
`;

const JobPostingTitle = styled(H2)`
  ${FONT_BOLD};
  text-align: left;
  ${screenLessThan('1200px')} {
    text-align: center;
  }
`;
const JobPostingSubtitle = styled(P)`
  text-align: left;
  width: 80%;
  ${screenLessThan('1200px')} {
    text-align: center;
  }
`;

const JobPostingButton = styled(Button)`
  text-decoration: none;
  ${screenLessThan('1200px')} {
    width: 80%;
  }
`;

export default JobPosting;
